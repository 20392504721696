@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "OpenSans-light";
  src: url("./assets/fonts/OpenSans-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("./assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("./assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url("./assets/fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-display: swap;
}

.swiper {
  width: 100%;
}

/* -------- Cookie Banner -------- */

.cc-nb-okagree {
  border: 1px solid #eaeaea !important;
  background-color: #eaeaea !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.cc-nb-okagree:hover {
  border: 1px solid #fff !important;
  background-color: #000 !important;
  color: #fff !important;
  outline: none !important;
  box-shadow: none !important;
}

.cc-nb-reject {
  border: 1px solid #eaeaea !important;
  background-color: #eaeaea !important;
  outline: none !important;
  box-shadow: none !important;
}

.cc-nb-reject:hover {
  border: 1px solid #eaeaea !important;
  border: 1px solid #fff !important;
  background-color: #000 !important;
  color: #fff !important;
  outline: none !important;
  box-shadow: none !important;
}

.cc-nb-changep {
  border: 1px solid #eaeaea !important;
  outline: none !important;
  box-shadow: none !important;
}

.cc-nb-changep:hover {
  border: 1px solid #fff !important;
  background-color: #000 !important;
  color: #fff !important;
  outline: none !important;
  box-shadow: none !important;
}

.termsfeed-com---nb-interstitial-overlay {
  background-color: #11111150 !important;
}

.termsfeed-com---reset {
  background-color: #11111195 !important;
  border-radius: 8px;
  font-family: "OpenSans-Regular";
}

.cc-nb-main-container {
}

.cc-nb-title {
  font-size: 20px !important;
}

.cc-nb-text {
  font-size: 14px !important;
}

.ot-sdk-show-settings {
  padding: 8px 0px 0px 0px !important;
  text-align: left !important;
  font-size: 16px !important;
  color: #fff !important;
  justify-content: left !important;
  text-transform: none !important;
  border: none !important;
}

.ot-sdk-show-settings:hover {
  color: #9e9e9e !important;
  background: #000 !important;
}

b {
  font-family: "OpenSans-Bold";
}
